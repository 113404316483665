<template>
  <div
    class="test-plan-duplicate font-inter py-2"
    fluid
  >
    <v-card
      class="test-plans-section-header white pt-4 px-6 pb-4 mb-2"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div>
        <button
          plain
          class="btn-nav-back font-inter"
          @click="handleBackClick"
        >
          <v-icon color="blue">
            mdi-chevron-left
          </v-icon>
          <span class="d-flex-inline justify-center align-center ma-0 blue--text">
            {{ $t('plans.create.backToCreatePlans') }}
          </span>
        </button>
      </div>
      <h2 class="mt-4">
        {{ $t('plans.duplicate.title') }}
      </h2>
      <div class="mt-4 d-flex">
        <v-chip
          class="font-inter font-weight-bold px-4"
          width="200px"
          label
          :class="{ 'blue--text': tab === 'ongoing' }"
          :color="tab === 'ongoing' ? 'blue-light' : 'gray-light'"
          @click="$emit('tab', 'ongoing')"
        >
          {{ $t('ongoing') }} <span class="ml-2">{{ activeItemCount }}</span>
        </v-chip>
        <v-chip
          class="font-inter font-weight-bold px-4 ml-2"
          label
          width="200px"
          :class="{ 'blue--text': tab === 'archived' }"
          :color="tab === 'archived' ? 'blue-light' : 'gray-light'"
          @click="$emit('tab', 'archived')"
        >
          {{ $t('archived') }} <span class="ml-2">{{ archivedItemCount }}</span>
        </v-chip>
      </div>
      <slot name="additional-actions" />
    </v-card>
    <v-card
      v-if="testPlans.length <= 0"
      class="test-plans-duplicate-wrapper pt-6 px-6"
    >
      <placeholder />
    </v-card>
    <v-card
      v-else
      class="test-plans-duplicate-wrapper pt-6 px-6"
      rounded="lg"
      elevation="0"
    >
      <div class="search-container d-flex">
        <search-field
          v-model="searchFilter"
          class="search-input mr-2"
          :placeholder="$t('searchByName')"
        />
        <v-btn
          class="d-flex align-center justify-center text-none px-0"
          height="40"
          color="gray-100"
          depressed
          @click="isOpenFilter = true"
        >
          <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
        </v-btn>
        <div class="ml-auto">
          <SettingsMenu 
            table-type="plansDuplicate" 
          />
        </div>
      </div>
      <plans-list
        class="plans-list"
        :plans="testPlans"
        :headers="listHeaders"
        :item-key="itemKey"
        :row-class="rowClass"
        :clear-selection="clearSelection"
        :single-select="true"
        @edit-item="editItem"
        @select-row="handleRowClick"
        @select-item="setselected"
        @archive-item="confirmArchiveTestPlan"
        @unarchive-item="confirmUnArchiveTestPlan"
        @delete-item="confirmDeletePlan"
        @add-to-milestone="handleAddToMilestione"
      />
      <div class="actions-container d-flex justify-end py-4 pr-6">
        <v-btn
          class="font-inter font-weight-bold text-none"
          dark
          depressed
          color="blue"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
          @click="handleDuplicate"
        >
          {{ $t('duplicate') }}
        </v-btn>
      </div>
    </v-card>
    <plans-list-filter
      :open="isOpenFilter"
      @filters="applyFilters"
      @close="isOpenFilter = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import SearchField from '@/components/Form/SearchField';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import PlansList from '@/views/Tests/Plans/Components/List/PlansList';
import makePlanService from '@/services/api/plan'
import { showErrorToast } from '@/utils/toast';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';

export default {
  components: {
    Placeholder,
    SearchField,
    PlansListFilter,
    PlansList,
    SettingsMenu,
  },
  data() {
    return {
      tab: 'ongoing',
      searchFilter: '',
      testPlans: [],
      itemKey: 'uid',
      rowClass: () => 'test-plan-item',
      clearSelection: false,
      isOpenFilter: false,
      headers: [],
      isColumnFilter: false,
      selecteditems: [],
    };
  },
  computed: {
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    listHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    activeItemCount() {
      return this.testPlans.filter((item) => item?.archivedAt === null).length;
    },
    archivedItemCount() {
      return this.testPlans.filter((item) => item?.archivedAt !== null).length;
    },
  },
  created() {
    if(!this.dynamicHeaders.plansDuplicate) {
      this.initializeHeaders({ type: 'plansDuplicate' });
    }
    this.headers = this.dynamicHeaders.plansDuplicate;
  },
  mounted() {
    this.getTestPlans();
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.testPlans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    handleTab(value) {
      this.tab = value;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlanCreate' });
    },
    handleClickItem(item) {
      this.$router.push({ name: 'TestPlanRerun', params: { handle: this.$route.params.handle, key: this.$route.params.key, planId: item.id } });
    },
    handleDuplicate() {

      if(this.selecteditems.length === 0) {
        this.$swal({
          title: this.$t('plans.duplicate.title'),
          text: this.$t('plans.duplicate.selectPlan'),
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      this.$store.dispatch('plan/findTestPlan', { planId: this.selecteditems[0].uid, handle: this.$route.params.handle, key: this.$route.params.key });
      const query = this.$route.query.activeAddMilestone ? { activeAddMilestone: 'true' } : {};
      this.$router.push(
        { name: 'TestPlanDuplicateAddRuns', 
        params: { handle: this.$route.params.handle, key: this.$route.params.key }, 
        query: query
      });
    },
    editItem(item) {
      this.selectedPlan = item;
      this.$router.push({
        name: 'TestPlanDetail',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid
        },
      });
    },
    handleRowClick(item) {
      this.$store.dispatch('plan/findTestPlan', { planId: item.uid, handle: this.$route.params.handle, key: this.$route.params.key });
      this.$router.push({ name: 'TestPlanDuplicateAddRuns', params: { handle: this.$route.params.handle, key: this.$route.params.key } });
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
      console.log(this.selecteditems, 'selecteditems')
    },
    confirmArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('unarchive')
    },
    confirmDeletePlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('delete')
    },
    handleAddToMilestione(item) {
      this.selectedPlan = item
      this.showAddToMilestoneDialog = true;
    },
  },
};
</script>

<style lang="scss">
.test-plan-duplicate {
  height: calc(100vh - 90px - 8px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-section-header {
  flex: 0 1 138px;
}

.test-plans-duplicate-wrapper {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 68px;

  display: flex;
  flex-direction: column;
}

.search-container {
  flex: 0 1 40px;
}

.plans-list {
  flex: 1 1 auto;
  overflow: auto;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;

  display: flex;
  align-items: center;
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
